import { useParams } from 'react-router-dom'
import { Box, Grid, Paper, Text } from '@mantine/core'
import { ProgramLayout } from '@ospace/layout'
import { ErrorBoundary } from '@ospace/shared'

import { Notes } from '../components/Notes'
import { useNotes } from '../hooks'

type NotesBoxPageProps = { campaignId: number }

const NotesPage = (props: NotesBoxPageProps) => {
  const { isFetching, data: notes } = useNotes(props.campaignId)

  return (
    <ProgramLayout status={isFetching ? 'loading' : 'ready'} pageId='programs' fluid={false}>
      <Grid>
        <Grid.Col span={12}>
          <Paper shadow='sm' p='md' withBorder>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Text size='xl' weight={700} sx={{ marginBottom: 'md' }}>
                Call notes
              </Text>
            </Box>
            <Box sx={{ overflow: 'auto' }}>
              <Notes notes={notes} />
            </Box>
          </Paper>
        </Grid.Col>
      </Grid>
    </ProgramLayout>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)

  return <NotesPage campaignId={campaignId} />
}

export const NotesPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
