import React, { forwardRef, useEffect, useState } from 'react'
import { Box, LoadingOverlay, Skeleton, Table as MTable } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { KTSVG } from '@ospace/shared'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  SortingState,
  Table as ReactTable,
  useReactTable,
} from '@tanstack/react-table'

export interface TableProps {
  columns: any
  data: any
  filter?: string
  hideSearch?: boolean
  defaultColumnWidth?: number
  setRowSelection?: OnChangeFn<RowSelectionState>
  rowSelection?: RowSelectionState
  manualPagination?: boolean
  rowCount?: number
  pagination?: PaginationState
  setPagination?: OnChangeFn<PaginationState>
  isLoading?: boolean
  isFetching?: boolean
  setServerSideFilter?: OnChangeFn<string>
  vendorSalesPersonFilter?: React.ReactNode
}

export type PaginationProps = {
  table: ReactTable<unknown>
}

const Pagination = ({ table }: PaginationProps) => {
  return (
    <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-start my-10'>
      <div className='pagination'>
        <div className='input-group input-group-sm'>
          <button
            aria-label='pagination-first-page'
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <i className='bi bi-chevron-double-left'></i>
          </button>
          <button
            aria-label='pagination-previous-page'
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <i className='bi bi-chevron-left'></i>
          </button>
          <button
            aria-label='pagination-next-page'
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <i className='bi bi-chevron-right'></i>
          </button>
          <button
            aria-label='pagination-last-page'
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <i className='bi bi-chevron-double-right'></i>
          </button>

          <select
            className='form-select rounded mx-2 my-1'
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>

          <span aria-label='pagination-page-index-of-size' className='flex items-center gap-1 m-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
        </div>
      </div>
    </div>
  )
}

// A debounced search input react component
const DebouncedSearchInput = ({
  value: initialValue,
  onChange,
  debounce = 200,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}

function TableSkeleton() {
  const SPACE = 20
  return (
    <div aria-label='table-loader'>
      <Skeleton height={18} radius='xl' />
      <Skeleton height={18} mt={SPACE} radius='xl' />
      <Skeleton height={18} mt={SPACE} radius='xl' />
      <Skeleton height={18} mt={SPACE} radius='xl' />
      <Skeleton height={18} mt={SPACE} radius='xl' />
    </div>
  )
}
export const Table = forwardRef(
  (
    {
      isLoading = false,
      columns,
      data,
      filter = '',
      hideSearch = false,
      defaultColumnWidth = 200,
      rowSelection,
      setRowSelection,
      manualPagination = false,
      rowCount = undefined,
      pagination,
      setPagination,
      setServerSideFilter,
      isFetching,
      vendorSalesPersonFilter,
    }: TableProps,
    ref
  ) => {
    const [globalFilter, setGlobalFilter] = useState(filter)
    const [sorting, setSorting] = useState<SortingState>([])

    const table = useReactTable({
      data,
      columns,
      state: {
        globalFilter,
        sorting,
        rowSelection,
        ...(rowSelection ? { rowSelection } : {}),
        ...(manualPagination ? { pagination } : {}),
      },
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      // override client side filter
      ...(setServerSideFilter ? { onGlobalFilterChange: setGlobalFilter } : {}),
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: setSorting,
      debugTable: false,
      autoResetAll: false,
      ...(setRowSelection ? { onRowSelectionChange: setRowSelection } : {}),
      // server side pagination
      ...(manualPagination
        ? {
            manualPagination,
            rowCount,
            onPaginationChange: setPagination,
          }
        : {}),
    })
    const [visible, { close, open }] = useDisclosure(true)
    useEffect(() => {
      if (isFetching) {
        open()
      } else {
        close()
      }
    }, [close, isFetching, open])

    useEffect(() => {
      if (typeof ref === 'function') {
      } else if (ref) {
        ref.current = table
      }
    }, [table, ref])

    if (isLoading) {
      return <TableSkeleton />
    }

    // Render the UI for your table
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {!hideSearch && (
            <div
              className='d-flex align-items-center position-relative mb-5'
              style={{
                paddingTop: '10px',
              }}
            >
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2x mt-1'
                />
              </span>

              <DebouncedSearchInput
                value={globalFilter ?? ''}
                onChange={(value) =>
                  setServerSideFilter
                    ? setServerSideFilter(value.toString())
                    : setGlobalFilter(String(value))
                }
                className='form-control form-control-solid w-300px ps-16'
                placeholder='Search'
              />
            </div>
          )}
          {vendorSalesPersonFilter && vendorSalesPersonFilter}
        </div>
        <Box pos='relative'>
          <LoadingOverlay aria-label='table-filter-loader' visible={visible} overlayBlur={2} />
          <MTable fontSize='md'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
                >
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan} className='fw-bolder text-muted '>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                            style: {
                              minWidth: header.getSize() || defaultColumnWidth,
                              width: header.column.getSize(),
                            },
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: <i className='bi bi-caret-down-square mx-2'></i>,
                            desc: <i className='bi bi-caret-up-square mx-2'></i>,
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className='text-light bg-white'>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      <div className='text-dark d-block mt-2 fs-6'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </MTable>
        </Box>
        <Pagination table={table} />
      </>
    )
  }
)
