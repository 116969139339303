import React, { ReactChild, useEffect, useRef, useState } from 'react'
import { Text } from '@mantine/core'

export const Spoiler = ({ lines, children }: { lines: number; children: ReactChild }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const contentRef: any = useRef(null)

  useEffect(() => {
    const checkContentHeight = () => {
      const contentHeight = contentRef?.current?.scrollHeight!
      const lineHeight = parseInt(window.getComputedStyle(contentRef?.current)?.lineHeight, 10)
      if (lineHeight * lines < contentHeight) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    checkContentHeight()
    window.addEventListener('resize', checkContentHeight)
    return () => window.removeEventListener('resize', checkContentHeight)
  }, [lines])

  return (
    <div style={{ marginBottom: '20px' }}>
      <div
        ref={contentRef}
        style={{
          maxHeight: isOpen ? 'none' : `calc(1.2em * ${lines})`,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease-in-out',
          lineHeight: '1.2em',
          maxWidth: '400px',
        }}
      >
        {children}
      </div>
      {showButton && (
        <Text
          c='blue'
          size='lg'
          onClick={() => setIsOpen(!isOpen)}
          style={{ marginTop: '10px', cursor: 'pointer' }}
        >
          {isOpen ? '- Hide' : '+ Show more'}
        </Text>
      )}
    </div>
  )
}
