import { Box } from '@mantine/core'
import { ProgramLayout } from '@ospace/layout'
import { VendorSalesPersonFilter } from '@ospace/shared'

import { Notes } from './Notes'

export const FilteredNotes = ({
  isFetching,
  data,
  label,
}: {
  isFetching: boolean
  label: string
  data: {
    notes: any[]
    vendorSalesPerson: {
      enabled: boolean
      data: any
    }
  }
}) => {
  const { SelectFilter, filteredData } = VendorSalesPersonFilter({
    data: data?.notes || [],
    vendorSalesPerson: data?.vendorSalesPerson?.data || [],
    filterKeyPath: 'company.id',
  })

  return (
    <ProgramLayout status={isFetching ? 'loading' : 'ready'} pageId='programs' fluid={false}>
      {data && (
        <Box mt='xl'>
          <div className={`card`}>
            <div className='card-header border-0'>
              <h3
                className='card-title align-items-start flex-column'
                style={{
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                    paddingTop: '20px',
                  }}
                >
                  <span className='card-label fw-bolder fs-3'>{label}</span>
                  {data?.vendorSalesPerson.enabled && { ...SelectFilter }}
                </div>
              </h3>
            </div>
            <div className={`card-body`} style={{ overflow: 'scroll' }}>
              <Notes notes={filteredData} />
            </div>
          </div>
        </Box>
      )}
    </ProgramLayout>
  )
}
