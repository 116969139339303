import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '@ospace/shared'

import { CampaignOverview } from '../../components/partials/dashboard/Overview'
import { useCampaign, useDealStatus } from '../../hooks'

import { CampaignLayout } from './CampaignLayout'

type Props = { campaignId: number }
export const ProgramMetricsPage = (props: Props) => {
  const { data: campaign, isSuccess } = useCampaign(props.campaignId)
  const { isFetching, data: dealStatus } = useDealStatus(props.campaignId)

  return (
    <CampaignLayout>
      {!!isSuccess && !isFetching && (
        <CampaignOverview campaign={campaign} dealStatus={dealStatus} />
      )}
    </CampaignLayout>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <ProgramMetricsPage campaignId={campaignId} />
}

export const ProgramMetricsRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
