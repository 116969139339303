import { API, Storage } from '@ospace/shared'
import { PaginationState } from '@tanstack/react-table'

const getContactsForCampaign = async (
  campaignId: number,
  pagination: PaginationState,
  vendorSalesPersonEmail: string,
  filter?: string
) => {
  return API.get('client', `/contacts/campaigns/${campaignId}?`, {
    queryStringParameters: {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      vendorSalesPersonEmail,
      filter,
    },
  })
}

const getContact = async (campaignId: number, contactId: number) => {
  return API.get('client', `/contacts/${contactId}/campaigns/${campaignId}`)
}

const getContactsForCompanies = async (campaignId: number, companyId: number) => {
  return API.get('client', `/contacts/campaigns/${campaignId}/companies/${companyId}`, {})
}

const getCompaniesForCampaign = async (campaignId: number) => {
  return API.get('client', `/companies/campaigns/${campaignId}`, {})
}

const getContactUploadStatus = async (importId: number) => {
  return API.get('client', `/contacts:upload/status/${importId}`, {})
}

const getContactUpload = async (importId: number) => {
  return API.get('client', `/contacts:upload/${importId}`, {})
}

const validateContact = async (contact: any) => {
  const key = `${Date.now()}-${contact.lookerClientId}-${contact.file.name}`
  let s3object
  if (contact.file) {
    s3object = await Storage().put(`uploads/${key}`, contact.file, {
      bucket: process.env.REACT_APP_PROCESSOR_BUCKET,
    })
  }

  const contactDTO = {
    ...contact,
    key: s3object ? key : '',
  }
  return API.post('client', `/contacts:validate`, {
    body: contactDTO,
  })
}

const processContact = async (contact: any) => {
  const key = `${Date.now()}-${contact.lookerClientId}-${contact.file.name}`
  let s3object
  if (contact.file) {
    s3object = await Storage().put(`uploads/${key}`, contact.file, {
      bucket: process.env.REACT_APP_PROCESSOR_BUCKET,
    })
  }

  const contactDTO = {
    ...contact,
    key: s3object ? key : '',
  }
  return API.post('client', `/contacts/csvs`, {
    body: contactDTO,
  })
}

const dissociateContactFromCampaign = async (contactId: number, campaignId: number) => {
  return API.del('client', `/contacts/${contactId}/campaigns/${campaignId}`, {})
}

const dissociateMultipleContactFromCampaign = async (contactIds: number[], campaignId: number) => {
  return API.post('client', `/contacts/campaigns/${campaignId}/dissociate`, {
    body: {
      ids: contactIds,
    },
  })
}

const getNotesForCampaign = async (campaignId: number) => {
  return API.get('client', `/notes/campaigns/${campaignId}`, {})
}

const getNotesForCampaignAndPerson = async (campaignId: number, personId: number) => {
  return API.get('client', `/notes/campaigns/${campaignId}/persons/${personId}`, {})
}

const getPotentialOpForCampaignAndPerson = async (campaignId: number, personId: number) => {
  return API.get(
    'client',
    `/notes:potential-opportunities/campaigns/${campaignId}/persons/${personId}`,
    {}
  )
}

export const ContactService = {
  processContact,
  validateContact,
  getContactsForCampaign,
  getContactsForCompanies,
  getCompaniesForCampaign,
  dissociateContactFromCampaign,
  dissociateMultipleContactFromCampaign,
  getNotesForCampaign,
  getNotesForCampaignAndPerson,
  getPotentialOpForCampaignAndPerson,
  getContactUploadStatus,
  getContactUpload,
  getContact,
}
