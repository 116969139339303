import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mantine/core'
import { ProgramLayout } from '@ospace/layout'

import { useCampaign } from '../../hooks'

// TODO: this is not a page, it is a container that gets campaignId from the route for some reason.
//   - rename to CampaignContainer
//   - take campaignId as a prop
export const CampaignLayout: FC = ({ children }) => {
  const params: any = useParams()
  const campaignId = Number(params?.id)
  const { isFetching } = useCampaign(campaignId)

  return (
    <ProgramLayout status={isFetching ? 'loading' : 'ready'} pageId='programs' fluid={false}>
      <Box mt='xl' mb='md'>
        {children}
      </Box>
    </ProgramLayout>
  )
}
