import { FC } from 'react'
import { Button } from 'react-bootstrap'
import { AccountType } from '@ospace/schemas'
import { CampaignCrm } from '@ospace/schemas/campaign/campaign'
import CustomSelect from '@ospace/shared/components/CSelect'
import { ErrorMessage, Field, FieldArray } from 'formik'

const Step6: FC<any> = ({ values }) => {
  return (
    <div className='w-xl-600px'>
      <h3 className='fv-row mt-15 mb-10'>CRM</h3>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span>CRM being used</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='CRM system used for this campaign'
          ></i>
        </label>
        <Field
          name='crm'
          fieldName='crm'
          component={CustomSelect}
          isMulti={false}
          options={[
            {
              label: CampaignCrm.HUBSPOT,
              value: CampaignCrm.HUBSPOT,
            },
            {
              label: CampaignCrm.FIVECRM,
              value: CampaignCrm.FIVECRM,
            },
          ]}
        />
      </div>

      <h3 className='fv-row mt-15 mb-10'>Looker</h3>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span>Looker client id</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your looker client id'
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='lookerClientId'
          placeholder=''
          value={values.lookerClientId || ''}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='lookerClientId' />
        </div>
      </div>

      <h3 className='fv-row mt-15 mb-10'>Bombora configuration</h3>

      <FieldArray name='intentReportConfigurations'>
        {({ remove, push }) => (
          <div>
            {values?.intentReportConfigurations?.map((field: any, index: number) => {
              return (
                <div className='d-flex  mt-5'>
                  <div className='fv-row me-10 flex-fill'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span>Report id</span>
                      <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Specify your report guid'
                      ></i>
                    </label>

                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      name={`intentReportConfigurations.${index}.reportId`}
                      placeholder=''
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`intentReportConfigurations.${index}.reportId`} />
                    </div>
                  </div>

                  <div className='fv-row me-10 '>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      <span>Select Account Type</span>
                    </label>

                    <Field
                      as='select'
                      className='form-control form-control-lg form-control-solid'
                      name={`intentReportConfigurations.${index}.accountType`}
                      placeholder=''
                    >
                      <option value=''> Select one</option>
                      <option value={AccountType.NetNew}>{AccountType.NetNew}</option>
                      <option value={AccountType.TargetAccount}>{AccountType.TargetAccount}</option>
                    </Field>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`intentReportConfigurations.${index}.accountType`} />
                    </div>
                  </div>

                  <div className='fv-row d-flex align-items-center'>
                    <Button variant='danger' size='sm' onClick={() => remove(index)}>
                      {' '}
                      X{' '}
                    </Button>
                  </div>
                </div>
              )
            })}

            <div className='fv-row text-danger'>
              <ErrorMessage
                name={`intentReportConfigurations`}
                render={(message) => typeof message !== 'object' && <>{message}</>}
              />
            </div>
            <div className='fv-row my-10'>
              <Button onClick={() => push({ reportId: undefined, accountType: undefined })}>
                {' '}
                Add
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  )
}

export { Step6 }
